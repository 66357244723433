import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Test from './test';

const App = () => {
	return (
		<React.Fragment>
			<React.StrictMode>
				<Router>
					<Switch>
						<Route exact path="/">
							<h1>Hello</h1>
						</Route>
						<Route path="/pokemon">
							<Test />
						</Route>
					</Switch>
				</Router>
			</React.StrictMode>
		</React.Fragment>
	);
};

export default App;
