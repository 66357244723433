import React from 'react';
import { Link } from 'react-router-dom';

const test = () => {
	return (
		<div>
			<Link to="/">Home</Link>
		</div>
	);
};

export default test;
